import React, { useState ,useEffect} from 'react';
import { Router } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';
import { createBrowserHistory } from 'history';
import MomentUtils from '@date-io/moment';
import { Provider as StoreProvider } from 'react-redux';
import { ThemeProvider } from '@material-ui/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { theme, themeWithRtl } from './theme';
import { configureStore } from './store';
import routes from './routes';
import ScrollReset from './components/ScrollReset';
import StylesProvider from './components/StylesProvider';
import DirectionToggle from './components/DirectionToggle';
import InfoContext from "./global/InfoContext"
import './mixins/chartjs';
import './mixins/moment';
import './mixins/validate';
import './mixins/prismjs';
import './mock';
import './assets/scss/main.scss';
import LoadingOverlay from 'react-loading-overlay'
import BounceLoader from 'react-spinners/BounceLoader'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const history = createBrowserHistory();
const store = configureStore();

function App() {
  const [statusF, setStatusF]=useState({data:null,allFacturas:null,id:0})
  const [sesion, setSesion]=useState(null)
  const [jwt,setJWT]=useState(null)
  const [status,setStatus]=useState(false)
  const [loading, setLoading]=useState(false)
  const toastRef = (val) => toast(val);

  useEffect(() => {
    const jwt =localStorage.getItem("JWT")
    const tipo =localStorage.getItem("TIPO")
    if(jwt!=null){
      setJWT(jwt)
      //SetloginState(true)
    }
    else setSesion(tipo)
    setStatus(true)
  }, [])
  if(status == false ) return null
  return (
      <StoreProvider store={store}>
        <InfoContext.Provider value={{
          statusF,toastRef, setStatusF,setLoading,
          jwt,setJWT,setSesion
          }}>
          <ThemeProvider theme={theme}>
            <StylesProvider direction='ltr'>
              <MuiPickersUtilsProvider utils={MomentUtils}>
              <LoadingOverlay active={loading} spinner={<BounceLoader />}>
                <Router history={history}>
                  <ScrollReset />
                  {jwt==null?renderRoutes(routes[1]):renderRoutes(routes[0])}
                </Router>
                </LoadingOverlay>
              </MuiPickersUtilsProvider>
            </StylesProvider>
          </ThemeProvider>
        </InfoContext.Provider>
        <ToastContainer />
      </StoreProvider>
    
  );
}

export default App;
